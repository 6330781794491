import { useNavigate } from "react-router-dom";
import BottomNav from "../Components/BottomNavigate/BottomNav";
import "react-tabs/style/react-tabs.css";
import Carousel from "react-bootstrap/Carousel";
import FloatButton from "../Components/FloatButton/FloatButton"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Home() {
  const navigate = useNavigate();

  // const [notify, setNotify] = useState(false);
  // const [userdetail, setUserdetail] = useState([]);

  // const getUserinfo = () => {
  //   axios
  //     .post(
  //       `${Fetch_Url}/me.php?action=getuserinfo&user=${window.$loginData}`,
  //       {}
  //     )
  //     .then((resp) => {
  //       // console.log(resp, "resp data");
  //       setUserdetail(resp?.data?.user_Data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const [bal, noticeInfo] = userdetail;
  // console.log(bal, noticeInfo, "bal, noticeInfo");

  // useEffect(() => {
  //   getUserinfo();
  // }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("loginData")) {
  //     navigate("/signin");
  //   }
  // }, []);

  window.$loginData = localStorage.getItem("loginData")
  // console.log(window.$loginData, "logindata");

  const redirect1 = () => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
    else {
      navigate("/win")
    }
  }

  const redirect2 = () => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
    else {
      navigate("/aviator")
    }
  }

  const redirect3 = () => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
    else {
      navigate("/luckyhit")
    }
  }

  const redirect4 = () => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
    else {
      navigate("/dice")
    }
  }

  const redirect5 = () => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
    else {
      navigate("/andarbahar")
    }
  }

  const redirect6 = () => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
    else {
      navigate("/mine")
    }
  }

  const redirect7 = () => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
    else {
      navigate("/teenpatti")
    }
  }

  const soon = () => {
    toast.success("Game is coming soon", {
      position: toast.POSITION.BOTTOM_LEFT,
      toastId: "success"
    });
  }

  // const redirectLudo = () => {
  //   if (!localStorage.getItem("loginData")) {
  //     navigate("/signin");
  //   }
  //   else {
  //     navigate("/ludo")
  //   }
  // }

  return (
    <>
      <ToastContainer autoClose={700} />
      <BottomNav />
      <FloatButton />
      <div className="homeNav">
        {/* <h1>Win1 Club</h1> */}
        <img src="bharat365.png" alt="" />
        <i className="fa fa-bell"></i>
      </div>
      <div className="homeCont">
        <div>
          <Carousel>
            <Carousel.Item>
              <div className="sliderImg">
                <img src="IMG-20240904-WA0018.jpg" alt="" />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="sliderImg">
                <img src="IMG-20240904-WA0019.jpg" alt="" />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="allgameBox">
          <div className="gameHead">
            <h3>My Games</h3>
          </div>
          <div className="gameBox">
            <div className="gameCard" onClick={redirect1}>
              <img src="wingo_enter.png" alt="" />
              {/* <h4>Win Go</h4> */}
            </div>
            <div className="gameCard" onClick={redirect2}>
              <img src="aviator_enter.png" alt="" />
              {/* <h4>Aviator</h4> */}
            </div>
          </div>
          <div className="gameBox">
            <div className="gameCard" onClick={redirect3}>
              <img src="lucky_hit_enter.png" alt="" />
            </div>
            <div className="gameCard" onClick={redirect4}>
              <img src="dice.23747e64.jpg" alt="" />
            </div>
          </div>
          <div className="gameBox">
            <div className="gameCard" onClick={redirect5}>
              <img src="AnB.4c7b9686.jpg" alt="" />
            </div>
            <div className="gameCard" onClick={redirect6}>
              <img src="MineSweeper.22cd4b79.png" alt="" />
            </div>
          </div>
          {/* <div className="gameBox">
            <div className="gameCard" onClick={redirect7}>
              <img src="teenpatti.png" alt="" />
            </div>
          </div> */}
          {/* <div className="gameBox">
            <div className="gameCard" onClick={redirectLudo}>
              <img src="360_F_94642486_eISVocQwYVqM7cZJ3QTlTCxUJ6K0You2.jpg" alt="" />
              // <h4>Ludo</h4>
            </div>
            <div className="gameCard" onClick={redirectLudo}>
              <img src="360_F_94642486_eISVocQwYVqM7cZJ3QTlTCxUJ6K0You2.jpg" alt="" />
              // <h4>Ludo</h4>
            </div>
          </div> */}
        </div>
        <div className="allgameBox">
          <div className="gameHead">
            <h3>Upcoming Games</h3>
          </div>
          <div className="gameBox">
            <div className="gameCard" onClick={soon}>
              <img src="super.png" alt="" />
            </div>
            <div className="gameCard" onClick={soon}>
              <img src="fortune.png" alt="" />
            </div>
          </div>
          <div className="gameBox">
            <div className="gameCard" onClick={soon}>
              <img src="boy.png" alt="" />
            </div>
            <div className="gameCard" onClick={soon}>
              <img src="new.png" alt="" />
            </div>
          </div>
          <div className="gameBox">
            <div className="gameCard" onClick={soon}>
              <img src="fish.png" alt="" />
            </div>
            <div className="gameCard" onClick={soon}>
              <img src="dino.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* Home CSS */
